.footer {
  position: fixed;
  bottom: 0;
  padding: 16px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__wrapper {
    font-size: 12px;
    opacity: 0.4;
  }
}
