.bubble-view {
  &__wrapper {
    width: 70vw;
    margin: 0 auto;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    transform: scale(1.4);

    @media screen and (max-width: 768px) {
      width: 90vw;
    }

    @media screen and (max-width: 640px) {
      width: 120vw;
    }

    @media screen and (max-width: 480px) {
      width: 150vw;
    }
  }

  .bubble {
    position: absolute;
    border-radius: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 1;
      position: absolute;
      left: 0;
    }
  }
}
